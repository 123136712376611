.modal-overlay {
  background: rgba(6,5,33,0.85);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  display: none;
}

.tariffs {
  position: fixed;
  z-index: 13;
  top: 0;
  left: 0;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //transition: all 0.2s ease-out;
  transform: translateX(-100%);

  .tariffs-title {
    color: white;
    text-align: center;
    font-family: 'Lato', Arial, sans-serif;
    font-size: 45px;
    text-transform: uppercase;
    margin-bottom: 37px;
    font-weight: 500;
  }

  .tariffs-subtitle {
    font-family: 'Lato', Arial, sans-serif;
    font-size: 14px;
    color: white;
    font-weight: 200;
    margin-bottom: 6px;
  }

  .close-tarrifs {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -130px;
    right: 130px;
    background: none;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;


    &::before, &::after {
      content: '';
      width: 100%;
      height: 1px;
      background: #a5a2eb;
      position: absolute;
      transition: $anim;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }

    &:hover::before, &:hover::after {
      background: #f2b56c;
    }
  }

  .tariffs-list {
    width: 100%;
    max-width: 1260px;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background: url(../img/table.svg) no-repeat;
      position: absolute;
      left: 0;
      top: 0;
    }

    li {
      display: flex;

      &:nth-of-type(2n+1) {
        .cell1 {
          background: #d8d8d8;
        }

        .cell2 {
          background: #e4e3df;
        }
      }

      &:nth-of-type(2n) {
        .cell1 {
          background: white;
        }

        .cell2 {
          background: white;
        }
      }
    }

    .cell1 {
      padding:18px 52px 18px 45px;
      width: 485/1260*100%;
      font-family: LM, Arial, sans-serif;
      font-size: 20px;
      color: #444343;
      display: flex;
      align-items: center;
    }

    .cell2 {
      width: (1260 - 485) / 1260 * 100%;
      padding: 18px 45px;
      font-family: "Lato", Arial, sans-serif;
      font-size: 20px;
      font-weight: 200;
      color: #444343;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .center-cell {

    }
  }

  .enter-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}


.tarif-open {
  transform: none;
  transition: all 0.6s ease-out;
}

.call-tarif {
  cursor: pointer;
}
