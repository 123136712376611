.contacts {
  position: relative;
}

#map {
  position: relative;

  width: 100%;
  height: 100vh;
}

.contacts-sect {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  height: 100vh;

  background: rgba($color: #484d8b, $alpha: .7);
}

.contacts-title {
  margin-top: 40px;

  font-size: 40px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}

.contacts-desc {
  font-size: 18px;
  color: #ffffff;
}

.contacts-addr {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;

  text-align: center;
  color: #ffffff;

  &::before {
    content: "";

    display: block;
    width: 18px;
    height: 28px;
    margin-bottom: 10px;

    background: url("../img/mark_mini.png") no-repeat center center;
  }
}

.contacts-tel {
  display: flex;
  margin-top: 20px;

  color: #ffffff;

  &::before {
    content: "";

    display: block;
    width: 16px;
    height: 23px;
    margin-right: 10px;

    background: url("../img/Telephone.png") no-repeat center center;
  }
}

.contacts-list {
  display: flex;
  margin-top: 50px;

  & .vk {
    background: url('../img/social_contacts.svg');

    &:hover {
      background: url('../img/social_contacts.svg') 19px 0;
    }
  }

  & .fb {
    background: url('../img/social_contacts.svg') 0px -29px;

    &:hover {
      background: url('../img/social_contacts.svg') 19px -29px;
    }
  }

  & .inst {
    background: url('../img/social_contacts.svg') 0px -61px;

    &:hover {
      background: url('../img/social_contacts.svg') 21px -61px;
    }
  }

  & .tw {
    background: url('../img/social_contacts.svg') 0px -88px;

    &:hover {
      background: url('../img/social_contacts.svg') 23px -88px;
    }
  }
}

.contacts-list__link {
  display: block;
  margin: 0 25px;

  width: 21px;
  height: 20px;

  transition: none;


}

.contacts-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.form__name,
.form__mail {
  width: 400px;
  height: 40px;
  padding-left: 15px;
  margin-bottom: 20px;

  font-size: 14px;
  color: #ffffff;

  background: none;
  border: 1px solid #f5cb99;
  outline: none;

  transition: $anim;

  &::placeholder {
    color: #ffffff;
  }

  &:focus {
    background: rgba($color: #f2b56c, $alpha: .2)
  }
}

.form__mess {
  width: 400px;
  height: 160px;
  padding-left: 15px;
  padding-top: 10px;
  box-sizing: border-box;

  font-size: 14px;
  color: #ffffff;

  background: none;
  border: 1px solid #f5cb99;
  outline: none;

  transition: $anim;

  &::placeholder {
    color: #ffffff;
  }

  &:focus {
    background: rgba($color: #f2b56c, $alpha: .2)
  }
}

.form__btn {
  display: flex;
  align-items: center;
  width: 240px;
  height: 50px;
  padding-left: 40px;
  margin-top: 20px;

  text-align: left;
  color: #ffffff;

  background: none;
  border: 3px solid #efbb7d;
  outline: none;

  transition: $anim;

  &:focus {
    background: rgba($color: #f2b56c, $alpha: .2)
  }
}

.form__btn-arrow {
  display: block;
  width: 24px;
  height: 29px;
  margin-left: 60px;

  background: url("../img/left_arr.svg") no-repeat center center;
}