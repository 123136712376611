//  vars

$anim: 0.2s all ease-in;

// mixins

@mixin bg-cover {
  content: '';

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;

  background-color: #03010d;
  opacity: .9 ;

  @media screen and (max-width: 1000px) {
    opacity: 0.75;
  }
}


@mixin bg-cover_lighter {
  content: '';

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;

  background-color: #08041b;
  opacity: .8 ;

  @media screen and (max-width: 1000px) {
    opacity: 0.75;
  }
}

@mixin logo {
  position: fixed;
  z-index: 10;
  left: 50%;

  width: 249px;
  height: 149px;

  transform: translateX(-50%);
}

.tarif-btn {
  width: 240px;
  min-height: 65px;
  border: 3px solid #ffb45a;
  background: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 37px;
  padding-right: 41px;
  transition: $anim;
  font-family: 'Lato', Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  outline: none;

  &:hover {
    background: #0b0d3f;
  }
}

main {
  max-height: 100vh;
  overflow: hidden;
}


// fonts

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.woff') format('woff');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: LSB;
  src: url(../fonts/Lato-Semibold.woff) format('woff');
}

@font-face {
  font-family: LH;
  src: url(../fonts/Lato-Heavy.woff);
}

@font-face {
  font-family: LM;
  src: url(../fonts/Lato-Medium.woff);
}

@font-face {
  font-family: LTH;
  src: url(../fonts/Lato-Thin.woff)
}

// global

body {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
}

.title{
  position: absolute;
  right: 100px;
  top: 100px;

  font-size: 45px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}


// imports
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "reset";
@import "scroll";
@import "header";
@import "main-nav";
@import "index";
@import "consulting";
@import "contacts";
@import "about";
@import "faq";
@import "join";
@import 'tariffs';
@import "rules";
@import "_projects.scss";
@import "slick.css";
@import "media";


.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {


  &::before {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    top:0;
    left: 50%;
    background: #3134bc;
    opacity: 1;
  }
}

.ps__rail-y {
  opacity: 1;
}

.ps__thumb-y, .ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y, .ps__rail-y > .ps__thumb-y, .ps__rail-y > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y  {
  background: #3134bc;
  border-radius: 0;
  width: 9px;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  background: none;
}
