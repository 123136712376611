.projects {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  background: url(../img/projects-bg.jpg) no-repeat;
  background-size: cover;

  &::before {
    @include bg-cover_lighter;
  }

  .title {
    z-index: 2;
  }

  .projects-content {
    background: rgba(6,7,47,0.7);
    width: 65vw;
    padding: 35px 31px;
    height: 750/1080*100vh!important;;
    max-height: 750/1080*100vh!important;
    overflow-y: scroll;
    position: relative;
  }
}

.projects-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 180px;
  position: relative;
  z-index: 2;
  padding-right: 120px;

  p {
    background: none;
    line-height: 160%;
  }


  h3 {
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-family: 'Lato', Arial, sans-serif;
    font-size: 14px;
    font-weight: 800;
  }

  div {
    margin-bottom: 45px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .page-content {
    display: flex;
    flex-direction: column;
  }
}

.projects-list {
  display: flex;
  margin: 37px auto 50px;

  button {
    background: none;
    border: none;
    outline: none;
    color: white;
    font-family: 'Lato', Arial, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    padding: 9px 15px;
    position: relative;
    border-left: 1px solid #383bd7;
    border-right: 1px solid #383bd7;
  }

  li:first-of-type  {
    button {
      border-left: none;
    }

    .active-button {
      border-left: 1px solid #383bd7;
    }
  }

  li:last-of-type  {
    button {
      border-right: none;
    }

    .active-button {
      border-right: 1px solid #383bd7;
    }
  }

  .active-button {
    border: 1px solid #383bd7;
    color: #a8acff;
  }

}
