.join {
  position: relative;
  z-index: 1;


  min-height: 100vh;

  background: url('../img/join-bg.jpg') no-repeat;
  background-size: cover;

  &::before {
    @include bg-cover_lighter;
  }


  .join-content {
    background: rgba(6,7,47,0.7);
    width: 65vw;
    padding: 48px 53px;
    height: 660/1080*100vh!important;;
    max-height: 660/1080*100vh!important;
    overflow-y: scroll;
    position: relative;
  }

}

.joint-tarif-btn {
  transform: translateY(35px);
}

.join-wrap {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding-top: 219px;
  align-items: center;
  padding-right: 120px;

  p {
    padding-left: 0;
    padding-right: 0;
    background: none;
  }

  .page-content {
    display: flex;
    flex-direction: column;
  }
}

.joint-list_big-title {
  font-family: LH, Arial, sans-serif;
  font-size: 25px;
  color: white;
  text-align: center;
  margin-bottom: 31px;
}

.join-list_big {
  display: flex;
  justify-content: space-between;
  padding-top: 17px;
  width: 95%;
  position: relative;
  margin: 40px auto 21px;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background: #383bd7;
    top: 0;
    left: 50%;
  }

  .join-item {
    width: 50%;
  }

  .inner-list {
    list-style: none;
    padding: 0 37px;
    text-indent: 15px;
    counter-reset: inner;

    li {
      position: relative;
      counter-increment: inner;

      &::before {
        content: counter(inner) ". ";
        font-family: 'Lato', Arial, sans-serif;
        font-weight: 300;
        color: #575ad7;
        position: absolute;
        left: -15px;
        top: 10px;
      }
    }
  }
}

.join-list_small {
  margin: 61px auto 0px;

  .joint-list_big-title {
    margin-bottom: 17px;
  }

  .join-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 47px;
    text-align: center;
  }

  .inner-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    counter-reset: inner2;

    li {
      position: relative;
      counter-increment: inner2;

      &::before {
        content: counter(inner2) '. ';
        font-family: 'Lato', Arial, sans-serif;
        font-weight: 300;
        color: #a592f5;
        position: absolute;
        left: -15px;
        top: 10px;
      }
    }
  }
}
