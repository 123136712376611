.about {
  position: relative;
  z-index: 1;

  min-height: 100vh;

  background: url('../img/about-bg.jpg') no-repeat;
  background-size: cover;

  &::before {
    @include bg-cover;
  }
}
.about-wrap {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  //align-items: center;
  height: 120vh;
  padding-top: 15px;
}

.about-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 65%;

  text-align: center;
}

.about-item {

  &.swipe-open {
    h2 {
      font-size: 30px;
    }
  }

  & h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    text-transform: uppercase;

    background: rgba($color: #202178, $alpha: .7);

    transition: $anim;
    cursor: pointer;

    &:hover {
      background: #202178;
    }
  }

  &:first-child h2 {
    background: rgba($color: #282897, $alpha: .7);

    &:hover {
      background: #282897;
    }
  }

  &:last-child h2 {
    background: rgba($color: #111259, $alpha: .7);

    &:hover {
      background: #111259;
    }
  }

  & p {
    position: relative;

    padding: 10px 35px;

    text-align: left;
    color: #ffffff;
    text-indent: 15px;

    background: rgba($color: #06072f, $alpha: 1.0);
  }

  & span{
    position: relative;

    display: block;
    padding: 10px 35px;

    text-align: left;
    font-weight: 700;
    color: #ffffff;

    background: rgba($color: #06072f, $alpha: 1.0);
  }


  & .scroll-content{
    display: none;
    max-height: 45vh;
    position: relative;
  }

}

.about-item--active-1{
  position: relative;

  background-color: #282897 !important;

  &::before{
  content: "";
  position: absolute;
  top: 60px;
  right: 50%;
  z-index: 1;

  transform: translateX(50%);
  border: 10px solid transparent;
  border-top: 10px solid #282897;
  }
}

.about-item--active-2{
  position: relative;

  background-color: #202178 !important;

  &::before{
  content: "";
  position: absolute;
  top: 60px;
  right: 50%;
  z-index: 1;

  transform: translateX(50%);
  border: 10px solid transparent;
  border-top: 10px solid #202178;
  }
}

.about-item--active-3{
  position: relative;

  background-color: #111259 !important;

  &::before{
  content: "";
  position: absolute;
  top: 60px;
  right: 50%;
  z-index: 1;

  transform: translateX(50%);
  border: 10px solid transparent;
  border-top: 10px solid #111259;
  }
}

.page-content {
  & p {
    position: relative;

    padding: 10px 35px;

    text-align: left;
    color: #ffffff;

    background: rgba($color: #06072f, $alpha: 1.0);
  }

  & span{
    position: relative;

    display: block;
    padding: 10px 35px;

    text-align: left;
    font-weight: 700;
    color: #ffffff;

    background: rgba($color: #06072f, $alpha: 1.0);
  }

}

.about-item2 {
  .top-text {
    text-align: center;
    background: rgba(32,33,120,.7);
    padding: 0;
  }

  .scroll-content {
    max-height: 60vh;
  }

  .update-scroll {
    display: flex;
    flex-direction: column;

    p {
      font-size: 16px;
      text-transform: none;
      font-family: 'Lato', Arial, sans-serif;
      font-weight: 200;
      background: none;
      display: none;
    }
  }

  &.swipe-open {
    p {
      display: block;
    }
  }
}

.about-list_inner {
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
    padding-top: 23px;

    p {
      background: none;
      color: black;
      text-align: center;
      margin-bottom: 12px;
    }

    img {
      width: auto;
    }

    &:nth-of-type(1), &:nth-of-type(4) {
      background: rgba(182, 188, 245, 0.9);

    }

    &:nth-of-type(3), &:nth-of-type(2) {
      background: rgba(177, 183, 245, 0.9);
    }
  }


}
