/*************** SCROLLBAR BASE CSS ***************/

.scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    position: relative;
}

.scroll-wrapper>.scroll-content {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    width: auto !important;
}

.scroll-wrapper>.scroll-content::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.scroll-element {
    display: none;
}

.scroll-element,
.scroll-element div {
    box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
    cursor: default;
}

.scroll-textarea {
    border: 1px solid #cccccc;
    border-top-color: #999999;
}

.scroll-textarea>.scroll-content {
    overflow: hidden !important;
}

.scroll-textarea>.scroll-content>textarea {
    border: none !important;
    box-sizing: border-box;
    height: 100% !important;
    margin: 0;
    max-height: none !important;
    max-width: none !important;
    overflow: scroll !important;
    outline: none;
    padding: 2px;
    position: relative !important;
    top: 0;
    width: 100% !important;
}

.scroll-textarea>.scroll-content>textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
}




/*************** SCROLLBAR RAIL ***************/

.scrollbar-rail>.scroll-element,
.scrollbar-rail>.scroll-element div {
    border: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    z-index: 10;
}

.scrollbar-rail>.scroll-element {
    background-color: #06072f;
}

.scrollbar-rail>.scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}

.scrollbar-rail>.scroll-element .scroll-element_size {
    background-color: #3134bc;

}

.scrollbar-rail>.scroll-element .scroll-element_outer:hover .scroll-element_size {
    background-color: #3134bc;
}

.scrollbar-rail>.scroll-element.scroll-x {
    bottom: 0;
    height: 12px;
    left: 0;
    min-width: 100%;
    padding: 3px 0 2px;
    width: 100%;
}

.scrollbar-rail>.scroll-element.scroll-y {
    height: 100%;
    min-height: 100%;
    padding: 0 2px 0 3px;
    right: 0;
    top: 0;
    width: 12px;
}

.scrollbar-rail>.scroll-element .scroll-bar {
    background-color: #3134bc;

    // -webkit-border-radius: 2px;
    // -moz-border-radius: 2px;
    // border-radius: 2px;

    // box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}


.scrollbar-rail>.scroll-element .scroll-element_outer:hover .scroll-bar {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

/* scrollbar height/width & offset from container borders */

.scrollbar-rail>.scroll-content.scroll-scrolly_visible {
    left: -17px;
    margin-left: 17px;
}

.scrollbar-rail>.scroll-content.scroll-scrollx_visible {
    margin-top: 17px;
    top: -17px;
}

.scrollbar-rail>.scroll-element.scroll-x .scroll-bar {
    height: 10px;
    min-width: 10px;
    top: 1px;
}

.scrollbar-rail>.scroll-element.scroll-y .scroll-bar {
    left: 1px;
    min-height: 10px;
    width: 10px;
}

.scrollbar-rail>.scroll-element.scroll-x .scroll-element_outer {
    height: 15px;
    left: 5px;
}

.scrollbar-rail>.scroll-element.scroll-x .scroll-element_size {
    height: 2px;
    left: -10px;
    top: 5px;
}

.scrollbar-rail>.scroll-element.scroll-y .scroll-element_outer {
    top: 5px;
    width: 15px;
}

.scrollbar-rail>.scroll-element.scroll-y .scroll-element_size {
    left: 5px;
    top: -10px;
    width: 2px;
}

/* update scrollbar offset if both scrolls are visible */

.scrollbar-rail>.scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
    left: -25px;
}

.scrollbar-rail>.scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
    top: -25px;
}

.scrollbar-rail>.scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
    left: -25px;
}

.scrollbar-rail>.scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
    top: -25px;
}