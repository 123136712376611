.header{
  position: relative;
  z-index: 13;
}

.menu {
  position: fixed;
  z-index: 10;
  display: flex;
}
.menu__item {
  width: 70px;
  height: 70px;
  background-color:  #040524;
  transition: $anim;
}

.menu__item:hover {
  background-color: #0b0d3f;
}

.login{
  display: block;
  width:70px;
  height: 70px;
  outline: none;
  border: none;
  background: url("../img/LogIn.png") no-repeat 45% center;
}

.menu__burger{
  display: flex;
  justify-content: center;
  width: 70px;
  height: 70px;
  align-items: center;
  background: none;
  border: none;
  border-right: 1px solid #16169f;
}

.burger{
  width: 25px;
  height: 1px;
  margin-top: -20px;

  background-color: #ffffff;

  &::after{
    content: '';

    display: block;
    width: 25px;
    height: 1px;
    margin-top: 10px;

    background-color: #ffffff;
  }

  &::before{
    content: '';

    display: block;
    width: 25px;
    height: 1px;
    margin-top: 10px;

    background-color: #ffffff;
  }
}

.logo {
  @include logo;

  background: url("../img/Logo.svg") no-repeat center center;

  &:hover {
    background: url("../img/Logo_hover.svg") no-repeat center center;
  }
}

.menu-logo {
  @include logo;

  background: url("../img/Logo_menu.svg") no-repeat center center;
  background-size: contain;

  &:hover {
    background: url("../img/Logo_menu_hover.svg") no-repeat center center;
    background-size: contain;
  }
}

.logo-vert {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 25px;

  width: 54px;
  height: 494px;

  transform: translateY(-50%);
}

.menu-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  display: block;

  background-color: rgba($color: #0e083f, $alpha: .95);


  transform: translateX(-100%);
  transition: .5s all ease-in;
}

.menu-pageIn{
  transform: translateX(0%);
}

.menu-close {
  position: fixed;
  top: 30px;
  left: 30px;

  width: 40px;
  height: 40px;

  background: none;
  border: none;

  &:hover .close::after, &:hover .close {
    background-color: #7F88FF;
    transition: $anim;
  }
}

.close{
  width: 40px;
  height: 1px;

  background-color: #ffffff;
  transform: rotate(45deg);
  transition: $anim;

  &::after{
    content: '';

    display: block;
    width: 40px;
    height: 1px;

    background-color: #ffffff;
    transform: rotate(90deg);
    transition: $anim;
  }
}

.header__nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  margin-top: 175px;
  margin-left: 15%;
}

.header__nav-item {
  position: relative;

  display: flex;
  margin: 10px 0;

  &::after{
    content: '';

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -63px;

    display: block;
    height: 2px;
    width: 43px;

    background-color: none;

    transition: $anim;
  }

  &:hover::after{
    background-color: #ffffff;
  }
}

.header__nav-link {
  font-size: 35px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
}
.menu-right{
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 317px;
  height: 100vh;

  background-color: #090524;
}

.menu-right__list{
margin-top: 234px;
}

.menu-right__list-item{
  margin: 13px 0;
}

.menu-right__list-link {
  display: block;
  width: 20px;
  height: 21px;

  transition: none;
}

.vk{
  background: url('../img/social.svg');
  &:hover{
    background: url('../img/social.svg') 19px 0;
  }
}

.fb{
  background: url('../img/social.svg') 0px -29px;
  &:hover{
    background: url('../img/social.svg') 19px -29px;
  }
}

.inst{
  background: url('../img/social.svg') 0px -61px;
  &:hover{
    background: url('../img/social.svg') 21px -61px;
  }
}

.tw{
  background: url('../img/social.svg') 0px -88px;
  &:hover{
    background: url('../img/social.svg') 23px -88px;
  }
}
.menu-right__address{
  margin-bottom: 134px;

  color: #605a86;
}
