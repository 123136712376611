.rules {
  position: relative;
  z-index: 1;


  min-height: 100vh;

  background: url('../img/rules-bg.jpg') no-repeat;
  background-size: cover;

  &::before {
    @include bg-cover_lighter;
  }

  .title {
    position: absolute;
    z-index: 2;
  }

  .rules-content {
    position: relative;
    max-height: 73vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    //max-width: 1000px;
    padding-bottom: 40px;
    padding-right: 30px;
  }

  .rules-wrap {
    position: relative;
    z-index: 3;
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .rules-title {
    font-family: LTH, Arial, sans-serif;
    font-size: 25px;
    color: #d6d4ff;
    margin-bottom: 50px;
  }

  .rules-item {
    font-family: "Lato", Arial, sans-serif;
    font-size: 16px;
    font-weight: 200;
    color: white;
    position: relative;
    margin-bottom:80px;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 11px 6.5px 0 6.5px;
      border-color: #5e60f4 transparent transparent transparent;
      top: -20px;
      left: 50%;
    }
  }

}
