
@media (max-width: 1440px) {

  .consulting-list,
  .about-list,
  .faq-list {
    width: 55%;
  }

  .title {
    font-size: 32px;
    top: 20px;
    right: 260px;
  }

  .about-item {
    & .scroll-content {
      max-height: 35vh;
    }
  }

  .join .join-content {
    width: 60vw;
  }

   .title {
    right: 115px;
  }

  .joint-tarif-btn {
    transform: translateY(30px);
  }

  .rules .rules-content {
    max-width: 950/1440*100vw;
    margin-right: 100px;
  }

  .about-item2 .scroll-content {
    max-height: 50vh;
    height: 50vh;
  }

  .join-list_big {
    width: 100%;
  }

  .join .join-content {
    padding: 35px 20px;
  }
}

@media (max-width: 1366px) {
  .contacts-addr {
    margin-top: 20px;
  }




  .form__name,
  .form__mail {
    margin-bottom: 10px;
  }

  .form__mess {
    height: 100px;
  }

  .about-item {
    & .scroll-content {
      max-height: 25vh;
    }
  }

  .join-list_big {
    width: 100%;
  }

   .joint-tarif-btn {
     transform: translateY(25px);
   }

  .tariffs .tariffs-list {
    max-width: 1165px;
  }

  .about-item2 .scroll-content {
    max-height: 50vh;
    height: 50vh;
  }

}

@media (max-width: 1280px) {
  .logo-vert {
    height: 400px;

    transform: translateY(-40%);
  }

  .title {
    font-size: 28px;
  }

  .header__nav-link {
    font-size: 26px;
  }

  .header__nav-list {
    width: 400px;
    margin-top: 100px;
    margin-left: 10%;
  }

  .menu-right__list {
    margin-top: 100px;
  }

  .menu-right__address {
    margin-bottom: 70px;
  }

  .contacts-sect {
    width: 450px;
  }

  .contacts-title {
    margin-top: 20px;

    font-size: 28px;
  }

  .contacts-addr {
    margin-top: 20px;
  }

  .contacts-list {
    margin-top: 20px;
  }

  .contacts-form {
    margin-top: 20px;
  }

  .form__mess {
    height: 60px;
  }

  .join .join-content {
    width: 57vw;
  }

  .tariffs .tariffs-list {
    max-width: 1100px;
  }
}

@media screen and (max-height: 750px) {
  .form__mess  {
    height: 100px;
  }

  .contacts-addr {
    margin-top: 20px;
  }

  .contacts-addr {
    margin-top: 20px;
  }

  .contacts-form {
    margin-top: 60px;
  }

  .tariffs .tariffs-list .cell1, .tariffs .tariffs-list .cell2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


@media (max-height: 600px) {

  .consulting-wrap,
  .about-wrap, .faq-wrap {
    height: 100vh;
    //margin-top: 170px;
  }

  .consulting-list p {
    padding: 10px;
  }
  .joint-tarif-btn {
    transform: none;
  }

  .tariffs {
    margin-top: 120px;
  }

  .tariffs .close-tarrifs {
    top: -65px;
  }

  .tariffs .tariffs-title {
    margin-bottom: 10px;
  }

  .contacts-form {
    margin-top: 25px;
  }

}

@media screen and (min-width: 1001px) {
  .desctop-hidden {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .main-nav {
    display: none;
  }

  .logo-vert {
    display: none;
  }

  .menu-pageIn{
    display: flex;
    flex-direction: column;
  }

  .header__nav-list {
    width: 100%;
    margin: 0;
  }

  .menu-right {
    position: relative;
    width: 100%;
    padding-top: 30px;
    height: 45vh;
  }

  .mobile-hidden {
    display: none;
  }

  .login {
    position: fixed;
    left: 0;
    top: 0;
    background-color: #0b0d3f;
  }

  .menu {
    left: auto;
    right: 0;
  }

  main {
    max-height: 100%;
  }

  .logo, .menu-logo {
    width: 160px;
    height: 100px;
    background-size: contain;
  }

  .menu__item, .login, .menu__burger {
    width: 70px;
    height: 70px;
  }

  .menu-right__list {
    margin-top: 0;
    display: flex;
    margin: 0 -5px;

    li {
      margin: 0 5px;
    }
  }

  .menu-right__address {
    margin: 30px 0 70px;
  }

  .header__nav-list {
    padding-top: 100px;
  }

  .menu-close {
    left: auto;
    right: 30px;
  }

  .menu-page {
    transition: 0.3s ease-out;
  }

  .header__nav-link {
    font-size: 16px;
  }

  .menu-page {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .footer {
    position: relative;
    z-index: 2;
  }

  .title {
    position: static;
    margin-bottom: 30px;
    text-align: center;
  }

  .about-wrap {
    display: flex;
    flex-direction: column;
  }

  .about-list {
    width: 100%;
  }

  .about-item {
    &:first-of-type .scroll-content {
      display: block;
    }
  }

  .about-item .scroll-content {
    max-height: 100%;
  }

  .about-wrap, .consulting-wrap, .faq-wrap {
    height: auto;
  }

  .about-wrap {
    padding-top: 200px;
  }

  .about-item p, .about-item span {
    padding: 10px 20px;
  }

  .projects-wrap {
    padding-top: 150px;
    padding-right: 0;
  }

  .projects .projects-content {
    width: 100%;
    height: 100%;
    max-height: 100% !important;
  }

  .projects-list {
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;
    display: flex;
    padding: 0 20px;
  }

  .projects .projects-content {
    padding: 30px 0;
  }

  .page-content p, .page-content span {
    padding: 10px 20px;
  }

  .project-item {
    width: 50%;

    button {
      width: 100%;
      border: 1px solid #383bd7!important;
    }
  }

  .faq {
    min-height: 1px;
  }

  .faq-wrap {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 150px;
  }

  .faq-list {
    max-height: 100% !important;
    width: 100%;
    height: 100%;
    padding-right: 0;
    padding: 0 20px;
  }

  .faq-item h2 {
    text-align: center;
    padding-right: 20px;
  }

  .faq-item h2:before {
    position: relative;
    margin-right: auto;
    min-width: 70px;
  }

  .faq-item div {
    max-height: 100%;
  }

  .rules .rules-wrap {
    padding-top: 150px;

    .title {
      position: static;
    }
  }

  .rules .rules-content {
    padding: 0 20px;
    margin: 0;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding-bottom: 30px;
  }

  .tariffs .close-tarrifs {
    width: 30px;
    height: 30px;
  }

  .tariffs {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh);
    padding-top: 150px;
    margin-top: 0;

    .tariffs-list {
      overflow-y: scroll;
    }
  }

  .tariffs .tariffs-title {
    font-size: 38px;
  }

  .tariffs .tariffs-subtitle {
    padding:  0 10px;
  }

  .tariffs .tariffs-list li {
    flex-direction: column;
  }

  .tariffs .tariffs-list .cell1, .tariffs .tariffs-list .cell2 {
    width: 100%;
    padding: 20px;
  }

  .tariffs .tariffs-list li:nth-of-type(odd) .cell1,.tariffs .tariffs-list li:nth-of-type(2n) .cell1, .tariffs .tariffs-list li:nth-of-type(2n) .cell2, .tariffs .tariffs-list li:nth-of-type(odd) .cell2, .tariffs .tariffs-list li:nth-of-type(even) .cell1 {
    background: none;
  }

  .tariffs .tariffs-list li:nth-of-type(odd) {
    background: #d8d8d8;
  }

  .tariffs .tariffs-list li:nth-of-type(even) {
    background: white;
  }

  .tariffs .close-tarrifs {
    width: 40px;
    height: 40px;
    top: 90px;
    right: 30px;
  }

  .consulting-wrap {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 150px;
  }

  .consulting-list {
    width: 100%;
  }

  .consulting-item p {
    padding: 20px;
  }
  .consulting {
    min-height: 1px;
  }

  .consulting-item.swipe-open h2 {
    font-size: 20px;
  }

  .consulting-item:first-of-type p {
    display: block;
  }

  .join-wrap {
    padding-top: 150px;
    padding-right: 0;
  }

  .join .join-content {
    width: 100%;
  }

  .join .join-content {
    padding: 20px;
    overflow: hidden;
    height: 100%!important;
    max-height: 100% !important;
  }

  .join-list_big{
    flex-direction: column-reverse;
  }

  .join-list_big .join-item {
    width: 100%;
  }

  .join-list_big:before {
    display: none;
  }

  .join-list_big .inner-list {
    padding: 0;

    p {
      padding: 0!important;
      padding-bottom: 30px!important;
    }
  }

  .join-wrap p {
    padding: 0!important;
    padding-bottom: 30px!important;
  }

  .join-list_big .inner-list li:before {
    top: 0;
  }

  .join-list_big {
    margin-top: 20px;
  }

  .join-list_small {
    margin-top: 20px;
  }

  .join-list_small .inner-list {
    padding: 0;
    align-items: flex-start;

    p {
      text-indent: 15px;
    }
  }

  .join-list_small .inner-list li:before {
    left: 0;
    top: 0;
  }

  .contacts {
    display: flex;
    flex-direction: column;
  }

  .contacts-sect {
    position: static;
    height: auto;
    width: 100%;
    background: rgba(72,77,139,1);
    padding-bottom: 30px;
  }

  .contacts-form {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .form__mess {
    width: 100%;
    height: 100px;
  }

  .form__mail, .form__name {
    width: 100%;
  }

  .menu-right__address, .menu-right__address a {
    color: #7d85ef;
  }

  .logo:hover {
    background-size: contain;
  }

  .about-item2.swipe-open h2 {
    height: 90px;
  }
  .about-list_inner li {
    width: 100%;
  }

  .about-item2 .scroll-content{
    height: 100%;
    max-height: 100%;
  }

  .menu-right__address a {
    color: #7d85ef;
  }

  .menu-right {
    padding-top: 60px;
  }

  .about-item.swipe-open h2 {
    font-size: 20px;
  }

  .about-wrap {
    padding-top: 130px;
  }

  .header__nav-list {
    padding-top: 120px;
  }

  .join .joint-tarif-btn {
    margin-bottom: 50px;
  }

  .projects-list {
    display: none;
  }

  .project-slider {
    padding-top: 25px;
    margin-top: 15px;
    position: relative;

    &::before {
      content: '';
      width: calc(100% - 40px);
      top: 0;
      left: 20px;
      height: 1px;
      background: #7F88FF;
      position: absolute;
    }

    .slick-dots {
      display: flex;
      width: 100%;
      justify-content: center;
      button {
        width: 10px;
        height:10px;
        border-radius: 50%;
        background: #383bd7;
        border: none;
        margin: 0 5px;
        font-size: 0;
      }

      .slick-active button {
        background: #7F88FF;
      }
    }
  }
}

.menu-right__address {
  a {
    color: #7d85ef;
  }
}
