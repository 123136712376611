.consulting {
  position: relative;
  z-index: 1;

  min-height: 100vh;

  background: url('../img/consulting-bg.jpg') no-repeat;
  background-size: cover;

  &::before {
    @include bg-cover;
  }
}


.consulting-wrap {
  position: relative;
  z-index: 5;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.consulting-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 65%;

  text-align: center;
}

.consulting-item {

  & h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    text-transform: uppercase;

    background: rgba($color: #202178, $alpha: .7);

    transition: $anim;
    cursor: pointer;

    &:hover {
      background: #202178;
    }
  }

  &:first-child h2 {
    background: rgba($color: #282897, $alpha: .7);

    &:hover {
      background: #282897;
    }
  }

  &:last-child h2 {
    background: rgba($color: #111259, $alpha: .7);

    &:hover {
      background: #111259;
    }
  }

  & p {
    position: relative;
    padding: 15px 35px 25px;

    color: #ffffff;

    background: rgba($color: #06072f, $alpha: 1.0);

  }

  &.swipe-open {
    h2 {
      font-size: 30px;
    }
  }

  .scroll-content {
    position: relative;
    max-height: 40vh;
    display: none;
    @media screen and (max-width: 1000px) {
      max-height: 100%;
    }
  }


}

@media screen and (max-width: 1000px) {
  .consulting-item:first-of-type .scroll-content {
    display: block;
  }
}

.consulting-item--active-1{
  position: relative;

  background-color: #282897 !important;

  &::before{
  content: "";
  position: absolute;
  top: 60px;
  right: 50%;
  z-index: 1;

  transform: translateX(50%);
  border: 10px solid transparent;
  border-top: 10px solid #282897;
  }
}

.consulting-item--active-2{
  position: relative;

  background-color: #202178 !important;

  &::before{
  content: "";
  position: absolute;
  top: 60px;
  right: 50%;
  z-index: 1;

  transform: translateX(50%);
  border: 10px solid transparent;
  border-top: 10px solid #202178;
  }
}

.consulting-item--active-3{
  position: relative;

  background-color: #111259 !important;

  &::before{
  content: "";
  position: absolute;
  top: 60px;
  right: 50%;
  z-index: 1;

  transform: translateX(50%);
  border: 10px solid transparent;
  border-top: 10px solid #111259;
  }
}
